import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { ArrowBackIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
	Box,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Heading,
	Stack,
	Text,
	Link,
	Spinner,
	Thead,
	Tr,
	Th,
	TableContainer,
	Table,
	Tbody,
	Td,
	Avatar,
	Tag,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	Drawer,
	useDisclosure,
	Button,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lightgallery.css'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import LightGallery from 'lightgallery/react'

import React, { FC, useEffect } from 'react'

import Navbar from '~/components/Navbar'
import CreatePatientTracker from '~/components/forms/CreatePatientTracker'
import { clinicsListQuery } from '~/components/kanban/Board'
import { leadsListQuery } from '~/components/kanban/Column'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import { LeadsList, LeadsListVariables } from '~/components/kanban/__generated__/LeadsList'
import { StageStatusValues } from '~/constants'
import { createClinicOptions } from '~/helpers'
import { PersonDetails, PersonDetailsVariables } from '~/inputs/__generated__/PersonDetails'
import { personDetailQuery } from '~/inputs/person'

export const personPhotosQuery = gql`
	query PersonPhotos($origin: ID!) {
		photos(where: { origin: $origin }) {
			id
			link
		}
	}
`

const boxStyles = {
	borderRight: '1px solid #E8E8E8',
	padding: '20px',
}

const textStyles = {
	fontSize: '0.9em',
}

interface Props {
	personId: string
}

const PersonDetailView: FC<Props> = ({ personId }) => {
	const [fetchPersonDetails, { data: personDetails, loading: personDetailsLoading }] = useLazyQuery<
		PersonDetails,
		PersonDetailsVariables
	>(personDetailQuery)

	const [fetchPersonPhotos, { data: personPhotos }] = useLazyQuery(personPhotosQuery)

	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)

	const { isOpen, onClose, onOpen } = useDisclosure()
	const { data, loading } = useQuery<LeadsList, LeadsListVariables>(leadsListQuery, {
		notifyOnNetworkStatusChange: true,
		variables: {
			start: 0,
			limit: 100,
			sort: 'created_at:desc',
			where: {
				personId: personId,
			},
		},
	})

	useEffect(() => {
		if (personId) {
			fetchPersonDetails({
				variables: { personId },
			})
			fetchPersonPhotos({ variables: { origin: Number(personId) } })
		}
	}, [personId])

	const onCreateLead = () => {
		return onOpen()
	}

	const getCurrentClinic = (
		clinicOptions: { label: string; value: string | number }[],
		clinic?: string | null
	) => {
		if (!clinic) {
			return 'N/A'
		}
		return clinicOptions.find(item => item.value === Number(clinic))?.label ?? 'N/A'
	}

	const handleRedirectToLead = (id?: string) => {
		if (id) {
			return navigate(`/lead/${id}`)
		}
		return null
	}

	const onBack = () => {
		return navigate(-1)
	}

	const renderLeadsTableData = () => {
		if (loading) {
			return (
				<Flex justify='center' gap='1em' p='1em'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}
		if (!loading && !data) {
			return <Text sx={textStyles}>No data</Text>
		}
		return data?.leadsList?.leads?.map(lead => {
			return (
				<Tr key={lead?.id}>
					<Td>
						<Text
							sx={{ _hover: { textDecoration: 'underline' }, cursor: 'pointer', ...textStyles }}
							onClick={() => handleRedirectToLead(lead?.id)}
						>
							{lead?.id}
						</Text>
					</Td>
					<Td>
						<Text sx={textStyles}>{getCurrentClinic(clinicOptions, lead?.clinic?.id)}</Text>
					</Td>
					<Td>
						{lead?.assignees?.assignees?.length ? (
							<Flex flexWrap='wrap' gap={2} mt='1em'>
								{lead?.assignees?.assignees.map(assignee => (
									<Flex align='center' justify='start' key={assignee?.id}>
										<Avatar size='sm' name={assignee?.username} />
										<Text fontSize='0.8em' color='#64554B' ml={2}>
											{assignee?.username}
										</Text>
									</Flex>
								))}
							</Flex>
						) : (
							<></>
						)}
					</Td>
					<Td>
						<Flex mt='1em' gap='0.5em' flexWrap='wrap'>
							{!lead?.inquiries?.inquiries.length && <Text sx={textStyles}>No enquiries</Text>}

							{lead?.inquiries?.inquiries.map((inquiry, index) => (
								<Tag
									fontSize='0.8em'
									px='0.5em'
									py='0.2em'
									key={index}
									bg='#F8F8F8'
									color='#64554B'
									fontWeight={700}
									rounded='full'
									border='1px solid #64554B'
								>
									{inquiry?.name}
								</Tag>
							))}
						</Flex>
					</Td>
					<Td>
						<Box>
							{lead?.current_stage?.type && (
								<Tag size='sm' fontSize='0.65em' fontWeight='bold' colorScheme='gray'>
									{StageStatusValues[lead.current_stage?.type]}
								</Tag>
							)}
						</Box>
					</Td>
					<Td>
						<Box>
							<Text sx={textStyles}>{lead?.archive ? 'Yes' : 'No'}</Text>
						</Box>
					</Td>
					<Td>
						<Box>
							<Text sx={textStyles}>{lead?.closed ? 'Closed' : 'Active'}</Text>
						</Box>
					</Td>
				</Tr>
			)
		})
	}

	const clinicOptions = createClinicOptions(clinicsList?.data)

	const getWizardStatus = () => {
		if (!personDetails?.person?.wizard_step) {
			return {
				text: 'DID NOT STARTED',
				color: 'red',
			}
		}
		if (personDetails?.person?.wizard_step === 5) {
			return {
				text: 'COMPLETED',
				color: 'green',
			}
		}
		return {
			text: 'IN PROGRESS',
			color: 'orange',
		}
	}

	const buildQuizAnswersData = (): { title: string; answer: string | null | undefined }[] => {
		return [
			{
				title: 'Dream outcome',
				answer: personDetails?.person?.dream_outcome,
			},
			{
				title: 'Treatment reason',
				answer: personDetails?.person?.treatment_reason,
			},
			{
				title: 'Comes from',
				answer: personDetails?.person?.comes_from,
			},
		]
	}

	const renderQuizAnswers = () => {
		const quizAnswers = buildQuizAnswersData()
		const quizAnswersExist = quizAnswers.some(answer => answer.answer)
		if (!quizAnswersExist) {
			return (
				<Card mt={4}>
					<CardHeader>
						<Heading size='md'>Quiz Answers</Heading>
					</CardHeader>

					<CardBody>
						<Text sx={{ marginRight: '10px' }}>Coming Soon</Text>
					</CardBody>
				</Card>
			)
		}
		return (
			<Card mt={4}>
				<CardHeader>
					<Heading size='md'>Quiz Answers</Heading>
				</CardHeader>
				<CardBody>
					{quizAnswers.map(answer => {
						return (
							<Box key={answer.title} sx={{ padding: '10px 0' }}>
								<Heading size='xs' textTransform='uppercase'>
									{answer.title}
								</Heading>
								<Text pt='2' fontSize='sm'>
									{answer.answer ?? 'N/A'}
								</Text>
							</Box>
						)
					})}
				</CardBody>
			</Card>
		)
	}

	if (personDetailsLoading)
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)

	return (
		<>
			<Navbar />
			<Flex bg='#F2F2F2' height='3em' alignItems='center' px={12}>
				<Button
					title='Back'
					aria-label='Edit'
					bg='transparent'
					color='black'
					marginRight='10px'
					_hover={{ bg: 'transparent', color: '#EEAB7E' }}
					leftIcon={<ArrowBackIcon sx={{ width: '20px' }} />}
					size='m'
					onClick={onBack}
				>
					Back
				</Button>
			</Flex>
			<Flex height='calc(100vh - 5em - 3em)'>
				<Flex
					bg='white'
					minWidth='100%'
					padding='50px 25px 0 25px'
					direction='column'
					overflowY='scroll'
					pb={10}
					sx={{
						'@media screen and (max-width: 1200px)': {
							minWidth: '340px',
						},
					}}
				>
					<Card>
						<CardHeader>
							<Heading size='md'>Person Details</Heading>
						</CardHeader>

						<CardBody>
							<Stack spacing='4' sx={{ display: 'flex', flexDirection: 'row' }}>
								<Box sx={boxStyles}>
									<Heading size='xs' textTransform='uppercase'>
										Name
									</Heading>
									<Text pt='2' fontSize='sm'>
										{personDetails?.person?.first_name ?? ''}{' '}
										{personDetails?.person?.last_name ?? ''}
									</Text>
								</Box>
								<Box sx={boxStyles}>
									<Heading size='xs' textTransform='uppercase'>
										Email
									</Heading>
									<Text pt='2' fontSize='sm'>
										{personDetails?.person?.email ?? ''}
									</Text>
								</Box>
								<Box sx={boxStyles}>
									<Heading size='xs' textTransform='uppercase'>
										Phone
									</Heading>
									<Text pt='2' fontSize='sm'>
										{personDetails?.person?.phone ?? ''}
									</Text>
								</Box>
								{personDetails?.person?.dentally_uuid && (
									<Box sx={boxStyles}>
										<Heading size='xs' textTransform='uppercase'>
											Dentally Information
										</Heading>
										<Link
											sx={{
												display: 'flex',
												alignItems: 'center',
												marginTop: '10px',
												color: 'blue.500',
											}}
											href={`${process.env.GATSBY_DENTALLY_URL}/patients/${personDetails?.person?.dentally_uuid}/details`}
											isExternal
										>
											Dentally patient <ExternalLinkIcon mx='4px' />
										</Link>
									</Box>
								)}
								<Box sx={boxStyles}>
									<Heading size='xs' textTransform='uppercase'>
										Wizard Status
									</Heading>
									<Text pt='2' fontSize='sm' color={getWizardStatus().color}>
										{getWizardStatus().text}
									</Text>
								</Box>
							</Stack>
						</CardBody>
					</Card>
					<Card mt={4}>
						<CardHeader>
							<Heading size='md'>Person Leads</Heading>
						</CardHeader>

						<CardBody>
							<TableContainer>
								<Table variant='simple'>
									<Thead
										sx={{
											background: '#F2F2F2',
										}}
									>
										<Tr>
											<Th>Lead Id</Th>
											<Th>Clinic</Th>
											<Th>Assignees</Th>
											<Th>Enquiry</Th>
											<Th>Stage</Th>
											<Th>Archived</Th>
											<Th>Status</Th>
										</Tr>
									</Thead>
									<Tbody>{renderLeadsTableData()}</Tbody>
								</Table>
							</TableContainer>
							<Button
								type='button'
								onClick={onCreateLead}
								sx={{
									marginTop: '20px',
									background: '#2D3748',
									color: '#fff',
									fontSize: '17px',
									height: '40px',
									marginRight: '15px',
									width: '160px',
									textTransform: 'uppercase',
									borderRadius: '6px',
									_hover: {
										background: '#47546b',
									},
								}}
							>
								Add New Lead
							</Button>
						</CardBody>
					</Card>
					{personPhotos?.photos.length ? (
						<Card mt={4}>
							<CardHeader>
								<Heading size='md'>User Photos</Heading>
							</CardHeader>

							<CardBody>
								<Flex
									sx={{
										'.light-gallery': {
											display: 'flex',
											gap: 3,
											flexWrap: 'wrap',
											a: {
												borderRadius: '8px',
												width: '150px',
												height: '150px',
												display: 'flex',
												alignItems: 'center',
												background: '#2d3748',
												justifyContent: 'center',
												img: {
													width: '100%',
												},
											},
										},
									}}
								>
									<LightGallery
										speed={500}
										elementClassNames='light-gallery'
										plugins={[lgThumbnail, lgZoom]}
									>
										{personPhotos?.photos.map(photo => {
											return (
												<a key={photo.link} href={photo.link}>
													<img src={photo.link} alt='asd' />
												</a>
											)
										})}
									</LightGallery>
								</Flex>
							</CardBody>
						</Card>
					) : null}
					{renderQuizAnswers()}
					<Card mt={4}>
						<CardHeader>
							<Heading size='md'>Referrals</Heading>
						</CardHeader>

						<CardBody>
							<Text sx={{ marginRight: '10px' }}>Coming Soon</Text>
						</CardBody>
					</Card>
				</Flex>
				<Drawer placement='right' onClose={onClose} isOpen={isOpen} size='lg'>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerHeader
							sx={{
								background: 'linear-gradient(90deg, #C29265 0%, #B68150 100%)',
								padding: '20px 40px',
								color: '#fff',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Text sx={{ marginRight: '10px' }}>Add New Lead</Text>
						</DrawerHeader>
						<DrawerBody sx={{ padding: 0 }}>
							<CreatePatientTracker
								onClose={onClose}
								onlyLead
								dentallyPatient={null}
								defaultFormValues={{
									first_name: personDetails?.person?.first_name ?? '',
									last_name: personDetails?.person?.last_name ?? '',
									email: personDetails?.person?.email ?? '',
									phone: personDetails?.person?.phone ?? '',
								}}
							/>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Flex>
		</>
	)
}

export default PersonDetailView
